import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import { MyDataProvider } from "./providers/MyDataProvider";
import VSDataProvider from "./providers/VSDataProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { MainHome } from "./layout/Home";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import roletypes from './screens/roletypes'; 
import state from "./screens/state";
import city from "./screens/city";
import countries from "./screens/country";

import users from './screens/users';
import employee from './screens/employee';

import productcategory from './screens/productcategory';
import producttype from './screens/producttype';

// import hospitalinvoice from './screens/hospitalinvoice';


// import leavetype from './screens/leavetype';
// import holidaylist from './screens/holidaylist';
// import employeeleavelist from './screens/employeeleavelist'; 
// import employeeleaveapprove from './screens/employeeleaveapprove'; 
// import employeesalary from './screens/employeesalary';  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';
import productgroup from "./screens/productgroup";
import product from "./screens/product";
import contentpage from "./screens/contentpage";
import agronews from "./screens/agronews";
import productfamily from "./screens/productfamily";
import manufacture from "./screens/manufacture";
import taxhead from "./screens/taxhead";
import discount from "./screens/discount";
import productdisease from "./screens/productdisease";
import advisorypanel from "./screens/advisorypanel";
import Account from "./screens/account";
import userenq from "./screens/userenq";
import notifiaction from "./screens/notifiaction";
import uoms from "./screens/uoms";
import stage from "./screens/stage";
import companygroup from "./screens/companygroup";
import supplier from "./screens/supplier";
import hsngroup from "./screens/hsngroup";
import purchaseorder from "./screens/purchaseorder";
import goodsinwardorder from "./screens/goodsinwardorder";
import quotationorders from "./screens/quotationorders";
import saleorder from "./screens/saleorder";
import dispatchorder from "./screens/dispatchorder";
import partnerHomeSections from "./screens/partner-home-sections";





const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");
//console.log(process.env.REACT_APP_SERVER_URL, "==REACT_APP_SERVER_URL==");
const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        
        
        <Resource name="lookups" list={ListGuesser} edit={EditGuesser} />
        <Resource title="Role Type" name="role-types" {...roletypes} />
        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configs" {...configs} /> 
        <Resource title="product-categories" name="product-categories" {...productcategory} />
        <Resource title="product-types" name="product-types" {...producttype} />
        <Resource title="product-groups" name="product-groups" {...productgroup} />
        <Resource title="products" name="products" {...product} />
        
        <Resource title="Country" name="countries" {...countries} />
        <Resource title="City" name="cities" {...city} />
        <Resource title="States" name="states" {...state} />
        <Resource name="content-page-config-dets" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="content-page-configs" {...contentpage} />
        <Resource name="agro-news" {...agronews} />
        <Resource name="product-families" {...productfamily} />
        <Resource name="manufactures" {...manufacture} />
        <Resource name="tax-heads" {...taxhead} />
        <Resource name="discounts" {...discount} />
        <Resource name="product-diseases" {...productdisease} />
        <Resource name="product-diseases-sols" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="products" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="advisory-panels" {...advisorypanel}/>
        <Resource name="accounts" {...Account}/>
        <Resource name="account-image-maps" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="account-details" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="user-product-group-enqs" {...userenq}/>
        <Resource name="user-product-group-enq-dtls" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="notifications" {...notifiaction}/>
        <Resource name="uoms" {...uoms}/>
        <Resource name="stages" {...stage}/>
        <Resource name="company-groups" {...companygroup}/>
        <Resource name="company-facilities" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="company-cost-centers" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="company-branches" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="suppliers" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="accounts" {...supplier}/>

        <Resource name="hsn-groups" {...hsngroup}/>
        <Resource name="purchase-orders" {...purchaseorder}/>
        <Resource name="purchase-order-dtls" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="goods-inward-orders" {...goodsinwardorder}/>
        <Resource name="goods-inward-order-dtls" list={ListGuesser} edit={EditGuesser}/>
        <Resource name="sale-orders" {...saleorder}/>
        <Resource name="sale-order-dtls" list={ListGuesser} edit={EditGuesser}/>

        <Resource name="dispatch-orders" {...dispatchorder}/>
        <Resource name="dispatch-order-dtls" list={ListGuesser} edit={EditGuesser}/>
        

        <Resource name="quotation-orders" {...quotationorders}/>
        <Resource name="partner-home-sections" {...partnerHomeSections}/>
        
        
        
        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;

// ATBBjeBAqsrYHGEQMUKSrHVMU8xK7999AF07