import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink } from "react-csv";
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./report.css";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const MasterReport4 = (props) => {
    const classes = useStyles();
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');

    // Modal state
    const [open, setOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState<any>(null);
    const [assignee, setAssignee] = useState('');
    const [notes, setNotes] = useState('');
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [status, setStatus] = useState<any>(167);
    
    const [statusData, setStatusData] = useState<any>([{id:167,name:"Created"},{id:256,name:"Assigned"},{id:257,name:"Completed"},{id:258,name:"Rejected"},{id:259,name:"Cancelled by Customer"}]);

 
 
    
    
    const handleActionClick = (row) => {
        setOpen(true);
        setSelectedAction(null);
        setAssignee('')
        setNotes('')
        setSelectedRow(row);
        //data.ServiceBookingID, data.AssignedTo, data.ActionStatusID, data.ActionNotes
    };
    
    const handleClose = () => {
        setOpen(false);
        setAssignee(''); // Reset assignee on close
        
        setSelectedAction(null);
        setAssignee('')
        setNotes('')
        setSelectedRow(null);
    };

    
    const handleUpdateLog = () => {
        
        setLoading(true);
        apiClient.updateSericeLogs({ServiceBookingID:selectedRow.ServiceBookingID, AssignedTo:assignee, ActionStatusID:selectedAction, ActionNotes:notes}).then(res => {
            generateReport();
            setOpen(false);
            setAssignee(''); // Reset assignee on close
            
            setSelectedAction(null);
            setAssignee('')
            setNotes('')
            setSelectedRow(null);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            
        });
    };

    const handleStatusChange = (event) => {
        setSelectedAction(event.target.value);
    };

    const generateReport = () => {
        setFectched(false);
        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        if (startdate === "") { sDate = ""; }
        if (enddate === "") { eDate = ""; }

        let data = {
            fromDate: sDate,
            toDate: eDate,
            statusId: status
        };

        setLoading(true);

        apiClient.loadReport("/report/services", data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    return (
        <div>
            <div className="report-heading">
                {props.rptTtile}
            </div>
            <div className="filters">
                <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Insurer" onChange={(e) => { setStatus(e.target.value); }}  value={status} >
        <MenuItem value={0}>{"-All-"}</MenuItem>
          { statusData.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
        </Select>
      </FormControl>
                </div>

                {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length === 0 && <div>No Record found</div>}
                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div>
                
    
            </div>
            
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
            </div>
            <div className="report-data">
                {isLoading && <CircularProgress />}
                <div className="w3-responsive">
                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                        <thead>
                            <tr>
                                <th key={'a'}>{'Action'}</th>
                                {cols && cols.filter(x => x !== 'ServiceBookingID').map((item) => (
                                    <th key={item}>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <th key={'a'}>
                                        <Button variant="contained" onClick={()=>handleActionClick(row)}>Action</Button>
                                    </th>
                                    {cols && cols.filter(x => x !== 'ServiceBookingID').map((col, colIndex) => (
                                        <td key={rowIndex + "-" + colIndex}>
                                            {row[col]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal for Action */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Change Status</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel id="status-label">Select Status</InputLabel>
                        <Select
                            labelId="status-label"
                            value={selectedAction}
                            onChange={handleStatusChange}
                        >
                            <MenuItem value={256}>Assigned</MenuItem>
                            <MenuItem value={257}>Completed</MenuItem>
                            <MenuItem value={258}>Rejected</MenuItem>
                            <MenuItem value={259}>Cancelled by Customer</MenuItem>
                           
                        </Select>
                    </FormControl>
                    {selectedAction === 256 && (
                        <TextField
                            label="Assign To"
                            value={assignee}
                            onChange={(e) => setAssignee(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    )}

{ (
                        <TextField multiline
                            label="Notes"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button  disabled={isLoading} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={isLoading}  onClick={() => { 
                        // Handle the status change logic here
                        console.log("Status:", selectedAction, "Assignee:", assignee); 
                        handleUpdateLog();
                    }} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
