import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';

import { Timeline } from './screens/student/Timeline';
import { VC } from "./screens/video_conference/VC";
import {ChangePassword} from "./screens/changepassword/ChangePassword";
import {MasterReport} from "./screens/reports/MasterReport";
import {MasterReport2} from "./screens/reports/MasterReport2";
import {MasterReport3} from "./screens/reports/MasterReport3";
import {LeaveReport} from "./screens/reports/LeaveReport";
import {SalaryReport} from "./screens/reports/SalaryReport"; 
import {AttedanceSummaryReport} from "./screens/reports/AttedanceSummaryReport";
import { MasterReport4 } from './screens/reports/MasterReport4';
import { MasterReport5 } from './screens/reports/MasterReport5';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/timeline" render={(props) => <Timeline {...props}/>} />,
    <Route exact path="/vc/:id" render={(props) => <VC {...props}/>}  />,
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/generalquery" render={(props) => <MasterReport  rptTtile="General Query Report" rpt="GQ" {...props} />} />,
    <Route exact path="/hbr" render={(props) => <MasterReport rptTtile="Hospital Bill Report" rpt="HBR" {...props} />} />,
    <Route exact path="/hir" render={(props) => <MasterReport rptTtile="Hospital Invoice Report" rpt="HIR" {...props} />} />,
    <Route exact path="/hrc" render={(props) => <MasterReport rptTtile="Hospital Reclaim Report" rpt="HRC" {...props} />} />,
    <Route exact path="/applogs" render={(props) => <MasterReport2 rptTtile="App Log Report" rpt="HRC" {...props} />} />,
    <Route exact path="/servicebookings" render={(props) => <MasterReport4 rptTtile="Services " rpt="HRC" {...props} />} />,
    <Route exact path="/productliketobuy" render={(props) => <MasterReport5 rptTtile="Product like To Buy " rpt="HRC" {...props} />} />,
    
    <Route exact path="/invreport" render={(props) => <MasterReport3 rptTtile="Invoice Report" rpt="HRC" {...props} />} />,
    <Route exact path="/leavereport" render={(props) => <LeaveReport rptTtile="Leave Report" rpt="HRC" {...props} />} />,
    <Route exact path="/empsalreport" render={(props) => <SalaryReport rptTtile="Salary Report" rpt="HRC" {...props} />} />,
    <Route exact path="/attendreport" render={(props) => <AttedanceSummaryReport rptTtile="Attendance Report" rpt="HRC" {...props} />} />,
    
    
];
